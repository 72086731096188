import { yup } from '@swe/shared/ui-kit/components/form';

type FormFields = {
  message: string;
};

const VALIDATION_SCHEMA = yup.object().shape({
  message: yup.string().required('Message is required!'),
});

const INITIAL_VALUES: FormFields = {
  message: '',
};

const FORM = {
  initialValues: INITIAL_VALUES,
  validationSchema: VALIDATION_SCHEMA,
};

export type { FormFields };
export { VALIDATION_SCHEMA, INITIAL_VALUES, FORM };
