import cn from 'clsx';

import isEqual from 'lodash/isEqual';

import { ComponentType } from 'react';

import { Bullet, BulletColor, BulletSize } from '@swe/shared/ui-kit/components/bullet';
import Divider from '@swe/shared/ui-kit/components/divider';
import { CheckIcon, IconProps } from '@swe/shared/ui-kit/components/icon';
import { ComponentHasClassName, ComponentHasSize } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

const renderDivider = (visible: boolean) =>
  visible ? <Divider className={styles.divider} /> : <span className={styles.divider} />;

type StepperSize = BulletSize;

type Step<V = any> = {
  value: V;
  caption?: string;
  icon?: ComponentType<IconProps>;
};

type StepperProps<V = any> = ComponentHasClassName &
  ComponentHasSize<StepperSize> & {
    steps: Step<V>[];
    current?: V;
    onChange?: (next: V) => void;
    fillPrevious?: boolean;
  };

const Stepper = <V extends any>({
  className,
  size = 'md',
  steps,
  current,
  onChange,
  fillPrevious = true,
}: StepperProps<V>) => {
  const currentIndex = steps.findIndex((step) => isEqual(step.value, current));

  return (
    <div className={cn(styles.root, className)}>
      {steps.map((step, index) => {
        const isCurrent = currentIndex === index;
        const isPrevious = fillPrevious && index < currentIndex;
        const bullet = step.icon ? step.icon : isPrevious ? CheckIcon : `${index + 1}`;
        const color: BulletColor = isCurrent ? 'primary' : isPrevious ? 'success' : 'light';

        return (
          <div
            className={styles.step}
            key={String(step.value) + index}
          >
            <div className={styles.bulletWrapper}>
              {renderDivider(index > 0)}
              <Bullet
                onClick={() => onChange?.(step.value)}
                className={styles.bullet}
                size={size}
                value={bullet}
                color={color}
              />
              {renderDivider(index < steps.length - 1)}
            </div>
            {step.caption && <div className={styles.caption}>{step.caption}</div>}
          </div>
        );
      })}
    </div>
  );
};

export { Stepper };
export type { StepperSize, StepperProps, Step };
export default Stepper;
