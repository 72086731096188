import { Img } from '@swe/shared/ui-kit/components/image';
import Modal from '@swe/shared/ui-kit/components/modal';
import Stack from '@swe/shared/ui-kit/components/stack';
import { Text } from '@swe/shared/ui-kit/components/text';
import { ComponentTogglable } from '@swe/shared/ui-kit/types/common-props';
import { toDataURL } from 'qrcode';
import { useEffect, useState } from 'react';

import styles from './styles.module.scss';

type QRCodeViewerProps = ComponentTogglable & {
  qrData: string;
};

const QRCodeViewer = ({ visible, onClose, qrData = '' }: QRCodeViewerProps) => {
  const [dataUrl, setDataUrl] = useState('');

  useEffect(() => {
    if (qrData) {
      toDataURL(qrData)
        .then((data) => {
          setDataUrl(data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [qrData]);

  return (
    <Modal
      ariaLabel="QR Code"
      visible={visible}
      heading={
        <Text
          variant="headline"
          size="md"
        >
          QR Code
        </Text>
      }
      showCloseButton
      onClose={onClose}
    >
      <Stack>
        {dataUrl && (
          <Img
            className={styles.img}
            alt="QR Code"
            ratio={1}
            src={dataUrl}
            width={128}
          />
        )}
      </Stack>
    </Modal>
  );
};

export type { QRCodeViewerProps };
export { QRCodeViewer };
export default QRCodeViewer;
