import { CarIcon, EditIcon, PackageIcon, ShopIcon } from '@swe/shared/ui-kit/components/icon';
import { Step, Stepper } from '@swe/shared/ui-kit/components/stepper';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { isInDelivery } from '@swe/shop-ui/entities/profile/orders';
import { OrderProcessStatus } from 'entities/common/orders';
import { FullShopFulfillmentType } from 'entities/shop/info';

enum StepEnumerable {
  Confirmation = 'confirmation',
  Packing = 'packing',
  Ready = 'ready',
}

const DELIVERY_READY_STEP: Step<StepEnumerable> = {
  value: StepEnumerable.Ready,
  caption: 'Out for delivery',
  icon: CarIcon,
};

const DELIVERY_STEP: Step<StepEnumerable> = {
  value: StepEnumerable.Ready,
  caption: 'On the way',
  icon: CarIcon,
};

const PICKUP_STEP: Step<StepEnumerable> = {
  value: StepEnumerable.Ready,
  caption: 'Ready for pickup',
  icon: ShopIcon,
};

const BASE_STEPS: Step<StepEnumerable>[] = [
  {
    value: StepEnumerable.Confirmation,
    caption: 'Confirmation',
    icon: EditIcon,
  },
  {
    value: StepEnumerable.Packing,
    caption: 'Packing',
    icon: PackageIcon,
  },
];

const getSteps = (fulfillmentType: FullShopFulfillmentType, status: OrderProcessStatus) => {
  if ([FullShopFulfillmentType.Delivery, FullShopFulfillmentType.LocalDelivery].includes(fulfillmentType))
    return [...BASE_STEPS, isInDelivery(status) ? DELIVERY_STEP : DELIVERY_READY_STEP];
  if (
    [
      FullShopFulfillmentType.DriveThrough,
      FullShopFulfillmentType.CurbsidePickup,
      FullShopFulfillmentType.InStorePickup,
    ].includes(fulfillmentType)
  )
    return [...BASE_STEPS, PICKUP_STEP];

  return BASE_STEPS;
};

const getCurrent = (status: OrderProcessStatus) => {
  switch (status) {
    case OrderProcessStatus.OnTheWay:
    case OrderProcessStatus.ReadyForPickUp:
    case OrderProcessStatus.OutForDelivery:
      return StepEnumerable.Ready;
    case OrderProcessStatus.Confirming:
    case OrderProcessStatus.Confirmed:
    case OrderProcessStatus.DocumentVerification:
      return StepEnumerable.Confirmation;
    case OrderProcessStatus.Packing:
    case OrderProcessStatus.Packed:
      return StepEnumerable.Packing;
    case OrderProcessStatus.Cancelled:
    case OrderProcessStatus.Delivered:
    case OrderProcessStatus.PickedUp:
    case OrderProcessStatus.CancelledByTimeout:
      return null;
  }
};

type OrderStepProps = ComponentHasClassName & {
  status: OrderProcessStatus;
  fulfillmentType: FullShopFulfillmentType;
};

const OrderStep = ({ className, status, fulfillmentType }: OrderStepProps) => {
  const current = getCurrent(status);

  if (!current) {
    return null;
  }

  return (
    <Stepper<StepEnumerable>
      size="lg"
      className={className}
      current={current}
      steps={getSteps(fulfillmentType, status)}
    />
  );
};

export type { OrderStepProps };
export { OrderStep };
