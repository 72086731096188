import { Alert } from '@swe/shared/ui-kit/components/alert';
import { CarIcon } from '@swe/shared/ui-kit/components/icon';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

type ArrivedMessageProps = ComponentHasClassName & {
  message: string;
};

const OrderVisit = ({ className, message }: ArrivedMessageProps) => {
  return (
    <Alert
      className={className}
      color="success"
      icon={CarIcon}
      title="I have arrived"
    >
      {message}
    </Alert>
  );
};

export { OrderVisit };
export default OrderVisit;
