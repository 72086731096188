import Overlay, { OverlayProps } from '@swe/shared/ui-kit/components/google-map/components/overlay';
import { CarIcon } from '@swe/shared/ui-kit/components/icon';

import Pin from '@swe/shared/ui-kit/components/pin';

type DriverMarkerProps = Omit<OverlayProps, 'children'>;

const DriverMarker = ({ position, zIndex }: DriverMarkerProps) => {
  return (
    <Overlay
      position={position}
      zIndex={zIndex}
    >
      <Pin
        color="neutral"
        selfAligned
        icon={CarIcon}
      />
    </Overlay>
  );
};

export type { DriverMarkerProps };
export { DriverMarker };
