import Button from '@swe/shared/ui-kit/components/button';
import { ShopIcon } from '@swe/shared/ui-kit/components/icon';

import { Contacts, ContactsProps } from 'domains/profile/containers/order-details/components/contacts';

type StoreCardProps = {
  name: string;
  address: string;
  messageCount?: number;
  onCall?: () => void;
  onChat?: () => void;
};

const StoreCard = ({ name, address, messageCount, onCall, onChat }: StoreCardProps) => {
  const actions = [
    onCall && (
      <Button
        color="light"
        block
        onClick={onCall}
        key="call"
        name="call"
      >
        Call
      </Button>
    ),
    onChat && (
      <Button
        color="light"
        badge={messageCount === 0 ? undefined : messageCount}
        block
        onClick={onChat}
        key="chat"
        name="chat"
      >
        Chat
      </Button>
    ),
  ].filter(Boolean) as ContactsProps['items'][number]['actions'];

  return (
    <Contacts
      items={[
        {
          icon: ShopIcon,
          title: name,
          subtitle: address,
          actions,
        },
      ]}
    />
  );
};

export type { StoreCardProps };
export { StoreCard };
