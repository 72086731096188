import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

const NAME = '/Orders/Arrived';
const ArrivedEndpoint = createModernEndpoint<
  {
    id: number;
    comment: string;
  },
  undefined
>(NAME);

export { ArrivedEndpoint };
export default ArrivedEndpoint;
