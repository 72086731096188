import Button from '@swe/shared/ui-kit/components/button';
import { UserIcon } from '@swe/shared/ui-kit/components/icon';

import { Contacts, ContactsProps } from 'domains/profile/containers/order-details/components/contacts';

type DriverCardProps = {
  name: string;
  messageCount?: number;
  onCall?: () => void;
  onChat?: () => void;
};

const DriverCard = ({ name, messageCount, onCall, onChat }: DriverCardProps) => {
  const actions = [
    onCall && (
      <Button
        color="light"
        block
        onClick={onCall}
        name="call"
        key="call"
      >
        Call
      </Button>
    ),
    onChat && (
      <Button
        color="light"
        badge={messageCount === 0 ? undefined : messageCount}
        block
        onClick={onChat}
        name="chat"
        key="chat"
      >
        Chat
      </Button>
    ),
  ].filter(Boolean) as ContactsProps['items'][number]['actions'];

  return (
    <Contacts
      items={[
        {
          icon: UserIcon,
          title: 'Driver',
          subtitle: name,
          actions,
        },
      ]}
    />
  );
};

export type { DriverCardProps };
export { DriverCard };
