import Bullet from '@swe/shared/ui-kit/components/bullet';
import { ButtonProps } from '@swe/shared/ui-kit/components/button';
import { IconProps } from '@swe/shared/ui-kit/components/icon';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import { ComponentType, ReactElement, ReactNode } from 'react';

import styles from './styles.module.scss';

type ContactsProps = ComponentHasClassName & {
  items: {
    icon: ComponentType<IconProps>;
    title: ReactNode;
    subtitle: ReactNode;
    actions?: ReactElement<ButtonProps> | ReactElement<ButtonProps>[];
  }[];
};

const Contacts = ({ className, items }: ContactsProps) => {
  return (
    <div className={cx(styles.root, className)}>
      {items.map(({ title, subtitle, icon: Icon, actions }, idx) => (
        <div
          className={styles.item}
          key={idx}
        >
          <div className={styles.header}>
            <Bullet
              size="lg"
              value={Icon}
              className={styles.icon}
            />
            <div className={styles.contact}>
              <div className={styles.title}>{title}</div>
              <div className={styles.address}>{subtitle}</div>
            </div>
          </div>
          {Array.isArray(actions) && actions.length > 0 && <div className={styles.actions}>{actions}</div>}
        </div>
      ))}
    </div>
  );
};

export type { ContactsProps };
export { Contacts };
