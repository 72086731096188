import { yup } from '@swe/shared/ui-kit/components/form';

type FormFields = {
  date: string;
  timeWindow: string;
};

const VALIDATION_SCHEMA = yup.object().shape({
  date: yup.string().required('Date is required!'),
  timeWindow: yup.string().required('Time Window is required!'),
});

const INITIAL_VALUES: FormFields = {
  date: '',
  timeWindow: '',
};

const FORM = {
  initialValues: INITIAL_VALUES,
  validationSchema: VALIDATION_SCHEMA,
};

export type { FormFields };
export { VALIDATION_SCHEMA, INITIAL_VALUES, FORM };
