import { Alert } from '@swe/shared/ui-kit/components/alert';
import Button from '@swe/shared/ui-kit/components/button';
import Form from '@swe/shared/ui-kit/components/form';
import { BuilderContext } from '@swe/shared/ui-kit/components/form/core/builder';
import { ClockIcon } from '@swe/shared/ui-kit/components/icon';
import Modal from '@swe/shared/ui-kit/components/modal';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';
import { ComponentTogglable } from '@swe/shared/ui-kit/types/common-props';

import { useCallback, useMemo, useRef, useState } from 'react';

import useIntervals from 'common/use-cases/use-intervals';
import useTimeOffset from 'common/use-cases/use-time-offset';
import { FORM, FormFields } from 'domains/profile/containers/order-details/containers/change-pickup-time-window/form';
import { EditIntervalEndpoint } from 'endpoints/profile/orders/edit-interval';
import { GetOrderIntervalsV2Endpoint } from 'endpoints/profile/orders/get-intervals-v2';

type ChangePickupTimeWindowProps = ComponentTogglable & {
  orderId: number;
  onSubmit?(): void;
};

const ChangePickupTimeWindow = ({ visible, onClose, orderId, onSubmit }: ChangePickupTimeWindowProps) => {
  const handleSubmit = useCallback(
    async ({ timeWindow, date }: FormFields) => {
      try {
        await EditIntervalEndpoint.request({
          fulfillmentDate: date,
          id: orderId,
          fulfillmentIntervalId: timeWindow === 'null' ? null : parseInt(timeWindow, 10),
        });
        onSubmit?.();
        onClose?.();
      } catch (e) {
        console.error(e);
      }
    },
    [onClose, onSubmit, orderId],
  );

  const formRef = useRef<BuilderContext<FormFields>>(null);
  const { data } = GetOrderIntervalsV2Endpoint.useRequest(visible ? { orderId } : null);
  const [selectedDate, _setSelectedDate] = useState('');

  const { dateOptions, getIntervalOptions } = useIntervals(data?.intervals ?? []);

  const timeWindowOptions = useMemo(() => getIntervalOptions(selectedDate), [getIntervalOptions, selectedDate]);

  const setSelectedDate = useCallback((value: DateISOString) => {
    _setSelectedDate(value);
    formRef.current?.setValue('timeWindow', '');
  }, []);

  const offsetAlert = useTimeOffset();

  return (
    <Modal
      ariaLabel="Change pickup time window"
      visible={visible}
      heading={
        <Text
          variant="headline"
          size="md"
        >
          Change pickup time window
        </Text>
      }
      onClose={onClose}
    >
      <Form.Builder
        {...FORM}
        ref={formRef}
        onSubmit={handleSubmit}
        autofocus={false}
        name="change-pickup-time"
      >
        <Stack>
          <Form.Select
            name="date"
            label="Date"
            placeholder="Choose date"
            disabled={!dateOptions.length}
            options={dateOptions}
            onChange={setSelectedDate}
            staticNote={false}
          />
          <Form.Select
            name="timeWindow"
            label="Time Window"
            placeholder="Choose time window"
            disabled={!timeWindowOptions.length}
            options={timeWindowOptions}
            staticNote={false}
          />
          {offsetAlert.diffHours !== 0 && (
            <Alert
              icon={ClockIcon}
              color="neutral"
              title={`Date and time are local (${offsetAlert.timezoneName})`}
            />
          )}
          <Stack direction="row">
            <Button
              color="light"
              block
              onClick={onClose}
              name="done"
            >
              Cancel
            </Button>
            <Form.SubmitButton block>Done</Form.SubmitButton>
          </Stack>
        </Stack>
      </Form.Builder>
    </Modal>
  );
};

export type { ChangePickupTimeWindowProps };
export { ChangePickupTimeWindow };
export default ChangePickupTimeWindow;
