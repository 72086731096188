import Button from '@swe/shared/ui-kit/components/button';
import { usePrompt } from '@swe/shared/ui-kit/components/promt';

import { useCallback } from 'react';

import { productAnalyticsMap } from 'common/entities/product-analitycs';
import { useAnalytics } from 'common/providers/analytics';
import { AEventType, EcomItem } from 'common/providers/analytics/constants';
import { useCartUtils } from 'common/providers/cart';

import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import CartRestoreEndpoint, { CartRestorationItem, RestorationStrategy } from 'endpoints/cart/restore';

type BuyAgainProps = {
  products: CartRestorationItem[];
};

const BuyAgain = ({ products }: BuyAgainProps) => {
  const navigate = useRouterNavigate();
  const { mutate, setLocalAvailableItemsQuantity } = useCartUtils();
  const { confirm } = usePrompt();
  const { saleType } = useSaleType();

  const showConfirmationPopup = useCallback(async () => {
    await confirm({
      heading: 'Some products are unavailable',
      content: 'Sorry, but it seems like some products may be out of stock 🙁',
      confirmLabel: 'Okay',
      hideCancel: true,
      ariaLabel: 'Some products are unavailable',
    });
  }, [confirm]);
  const { platformOs } = usePlatformOs();
  const { pushEvent } = useAnalytics();
  const handleBuyAgain = useCallback(async () => {
    try {
      const cart = await CartRestoreEndpoint.request({
        saleType,
        restoreMode: RestorationStrategy.Merge,
        variants: products,
        platformOs,
      });
      const items = products.reduce<EcomItem[]>((acc, { qty, catalogProduct }, index) => {
        if (!catalogProduct) {
          return acc;
        }
        return acc.concat(
          productAnalyticsMap({
            product: catalogProduct,
            qtyInCart: qty,
            cart,
            index,
            analyticalItemListName: 'order',
            analyticalItemListId: 'order',
          }),
        );
      }, []);
      pushEvent(AEventType.ADD_TO_CART, items);
      setLocalAvailableItemsQuantity(cart.items.reduce((acc, { qty }) => acc + qty, 0));
      await mutate(cart);
      if (cart.cartRestoredPartially) {
        await showConfirmationPopup();
      }
      await navigate(Routes.Cart);
    } catch (e) {
      await showConfirmationPopup();
    }
  }, [
    saleType,
    products,
    platformOs,
    pushEvent,
    setLocalAvailableItemsQuantity,
    mutate,
    navigate,
    showConfirmationPopup,
  ]);

  return (
    <Button
      name="buy_again"
      block
      onClick={handleBuyAgain}
    >
      Buy Again
    </Button>
  );
};

export type { BuyAgainProps };
export { BuyAgain };
