import Button from '@swe/shared/ui-kit/components/button';
import Form from '@swe/shared/ui-kit/components/form';
import Modal from '@swe/shared/ui-kit/components/modal';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';
import { ComponentTogglable } from '@swe/shared/ui-kit/types/common-props';

import { useCallback } from 'react';

import { FORM, FormFields } from 'domains/profile/containers/order-details/containers/submit-arrival/form';
import ArrivedEndpoint from 'endpoints/profile/orders/arrived';

type SubmitArrivalProps = ComponentTogglable & {
  orderId: number;
  onSubmit?(): void;
};

const SubmitArrival = ({ visible, onClose, orderId, onSubmit }: SubmitArrivalProps) => {
  const handleSubmit = useCallback(
    (data: FormFields) => {
      try {
        ArrivedEndpoint.request({
          comment: data.message,
          id: orderId,
        });
        onSubmit?.();
        onClose?.();
      } catch (e) {
        console.error(e);
      }
    },
    [onClose, onSubmit, orderId],
  );

  return (
    <Modal
      ariaLabel="I have arrived"
      visible={visible}
      heading={
        <Text
          variant="headline"
          size="md"
        >
          I have arrived
        </Text>
      }
      onClose={onClose}
    >
      <Form.Builder
        {...FORM}
        name="submit-arrival"
        onSubmit={handleSubmit}
      >
        <Stack>
          <Form.Textarea
            name="message"
            label="Vehicle information"
            placeholder="Describe the make and model of your vehicle"
            minRows={3}
          />
          <Stack direction="row">
            <Button
              color="light"
              block
              onClick={onClose}
              name="cancel"
            >
              Cancel
            </Button>
            <Form.SubmitButton block>Done</Form.SubmitButton>
          </Stack>
        </Stack>
      </Form.Builder>
    </Modal>
  );
};

export type { SubmitArrivalProps };
export { SubmitArrival };
export default SubmitArrival;
