import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import { Alert } from '@swe/shared/ui-kit/components/alert';
import Button from '@swe/shared/ui-kit/components/button';
import { DatePresenter } from '@swe/shared/ui-kit/components/date-presenter';
import GoogleMap, { ShopMarker, UserMarker } from '@swe/shared/ui-kit/components/google-map';

import { DriverMarker } from '@swe/shared/ui-kit/components/google-map/components/markers/driver';
import { EditIcon, ChatIcon } from '@swe/shared/ui-kit/components/icon';
import Loader from '@swe/shared/ui-kit/components/loader';
import Stack from '@swe/shared/ui-kit/components/stack';

import Text from '@swe/shared/ui-kit/components/text';

import { useMemo } from 'react';

import styles from './styles.module.scss';

import { PageHeader } from 'common/components/page-header';
import { ProductCardConnected } from 'common/containers/product-card';
import { useGuest } from 'common/providers/guest';
import { Routes } from 'common/router/constants';
import { getBadgeColor } from 'common/utils/order';
import { Receipt } from 'domains/checkout/components/receipt';
import { Actions } from 'domains/profile/containers/order-details/components/actions';
import { Contacts } from 'domains/profile/containers/order-details/components/contacts';
import { DriverCard } from 'domains/profile/containers/order-details/components/driver-card';
import { FulfillmentInformation } from 'domains/profile/containers/order-details/components/fulfillment-information';
import { OrderStep } from 'domains/profile/containers/order-details/components/order-step';
import { OrderVisit } from 'domains/profile/containers/order-details/components/order-visit';
import { QRCodeViewer } from 'domains/profile/containers/order-details/components/qr-code-viewer';
import { RequiredDocumentsAlert } from 'domains/profile/containers/order-details/components/required-documents';
import { StoreCard } from 'domains/profile/containers/order-details/components/store-card';
import { BuyAgain } from 'domains/profile/containers/order-details/containers/buy-again';
import { ChangePickupTimeWindow } from 'domains/profile/containers/order-details/containers/change-pickup-time-window';
import { SubmitArrival } from 'domains/profile/containers/order-details/containers/submit-arrival';
import { useLogoutGuest } from 'domains/profile/containers/order-details/use-cases/use-logout-guest';
import { getOrderNumber, useOrderDetails } from 'domains/profile/use-cases/use-order-details';
import { isCanceled, isSuccessDone } from 'entities/profile/orders';

type OrderDetailsProps = {
  orderId: number;
};

const OrderDetails = ({ orderId: id }: OrderDetailsProps) => {
  const [isChangePickupTimeWindowVisible, openChangePickupTimeWindowClose, closeChangePickupTimeWindow] =
    useToggleable();
  const [isSubmitArrivalVisible, openSubmitArrival, closeSubmitArrival] = useToggleable();
  const [isQRCodeViewerVisible, openQRCodeViewer, closeQRCodeViewer] = useToggleable();

  const { skipNextLogout } = useLogoutGuest();

  const {
    storeBlock,
    receiptItems,
    order,
    headerDate,
    mapBlock,
    driverBlock,
    documentsAlertBlock,
    userEmail,
    hasArriveBtn,
    hasPickupTimeWindow,
    mutateOrder,
    orderVisitedAlertBlock,
    fulfillmentInformationBlock,
    hintHowToModifyBlock,
    orderedItems,
    cancelOrder,
    isLoading,
    isOrderBelongsDifferentStore,
  } = useOrderDetails(id, skipNextLogout);

  const driverBlockElement = useMemo(() => {
    return driverBlock && !order?.hideForPatient && <DriverCard {...driverBlock} />;
  }, [driverBlock, order?.hideForPatient]);

  const orderCaption = useMemo(() => {
    const orderFor = order?.patient && !order.hideForPatient ? <span>For {order.patient.name}, </span> : undefined;
    const createdAt = headerDate ? (
      <DatePresenter
        value={headerDate}
        format={!order?.hideForPatient ? 'dd MMM yyyy' : 'Pp'}
      />
    ) : undefined;

    return (
      <>
        {orderFor}
        {createdAt}
      </>
    );
  }, [headerDate, order?.hideForPatient, order?.patient]);

  const cancelOrderButtonElement = useMemo(
    () =>
      order?.canCancel &&
      !order?.hideForPatient && (
        <Button
          ariaLabel="Cancel order button"
          color="light"
          block
          onClick={cancelOrder}
        >
          Cancel order
        </Button>
      ),
    [cancelOrder, order?.canCancel, order?.hideForPatient],
  );

  const badgeChildren = useMemo(() => {
    if (!order) {
      return;
    }
    return isCanceled(order.processStatus) ? 'Canceled' : isSuccessDone(order.processStatus) ? 'Completed' : undefined;
  }, [order]);

  const breadcrumbs = useMemo(() => {
    return [{ text: `Order ${getOrderNumber(order)}` }];
  }, [order]);

  const { isGuest, isLoading: isLoadingGuest } = useGuest();

  if (isLoading) {
    return (
      <Loader
        size="xl"
        center
      />
    );
  }

  if (!order) {
    return <Text>No order found</Text>;
  }

  const { fulfillmentType, processStatus: status } = order || {};

  if (!fulfillmentType) {
    return null;
  }

  return (
    <>
      <Stack>
        <PageHeader
          caption={orderCaption}
          title={`Order ${getOrderNumber(order)}`}
          badge={
            badgeChildren
              ? {
                  color: getBadgeColor(order.processStatus),
                  children: badgeChildren,
                }
              : undefined
          }
          defaultBackRoute={Routes.ProfileOrders}
          breadcrumbs={isGuest ? breadcrumbs : undefined}
        />

        <OrderStep
          status={status}
          fulfillmentType={fulfillmentType}
        />
        {orderVisitedAlertBlock && <OrderVisit {...orderVisitedAlertBlock} />}
        {documentsAlertBlock && <RequiredDocumentsAlert documentsRequiredNames="" />}
        {fulfillmentInformationBlock && <FulfillmentInformation {...fulfillmentInformationBlock} />}
        {mapBlock && (
          <GoogleMap
            className={styles.map}
            fitPointsBounds={[mapBlock.userLocation, mapBlock.driverLocation]}
            controls={['zoom', 'fullscreen']}
            gestureHandling="greedy"
          >
            <ShopMarker
              shopId={mapBlock.storeId}
              position={mapBlock.storeLocation}
            />
            <UserMarker position={mapBlock.userLocation} />
            <DriverMarker position={mapBlock.driverLocation} />
          </GoogleMap>
        )}
        <Actions
          status={status}
          fulfillmentType={fulfillmentType}
          onArrive={hasArriveBtn ? openSubmitArrival : undefined}
          onChangePickupTime={hasPickupTimeWindow ? openChangePickupTimeWindowClose : undefined}
          onShowQRCode={userEmail ? openQRCodeViewer : undefined}
        />
        {driverBlockElement}
        {order.notes && (
          <Contacts
            items={[
              {
                title: 'Notes',
                subtitle: order.notes,
                icon: ChatIcon,
              },
            ]}
          />
        )}
        {storeBlock && <StoreCard {...storeBlock} />}
        {hintHowToModifyBlock && (
          <Alert
            icon={EditIcon}
            color="neutral"
          >
            Need to update or cancel your order? Call or chat our dispensary.
          </Alert>
        )}
        {!isOrderBelongsDifferentStore && order?.items && !isLoadingGuest && !isGuest && (
          <BuyAgain products={order.items} />
        )}
        {orderedItems && orderedItems.length > 0 && (
          <Stack>
            {orderedItems.map((item, itemIdx) => (
              <ProductCardConnected
                key={itemIdx}
                layout="horizontal"
                product={item}
                getQuantity={() => order?.items?.[itemIdx]?.qty ?? 0}
                action={false}
                index={itemIdx}
                analyticalItemListId="order"
                analyticalItemListName="order"
                hidePrice
              />
            ))}
          </Stack>
        )}
        <Receipt
          total={order?.receipt?.total ?? 0}
          items={receiptItems}
          defaultOpened={isSuccessDone(order.processStatus) || isCanceled(order.processStatus)}
        />
        {cancelOrderButtonElement}
      </Stack>
      {hasPickupTimeWindow && (
        <ChangePickupTimeWindow
          orderId={order.id}
          visible={isChangePickupTimeWindowVisible}
          onClose={closeChangePickupTimeWindow}
          onSubmit={mutateOrder}
        />
      )}
      {hasArriveBtn && (
        <SubmitArrival
          orderId={order.id}
          visible={isSubmitArrivalVisible}
          onClose={closeSubmitArrival}
          onSubmit={mutateOrder}
        />
      )}
      {userEmail && (
        <QRCodeViewer
          visible={isQRCodeViewerVisible}
          onClose={closeQRCodeViewer}
          qrData={userEmail}
        />
      )}
    </>
  );
};

export { OrderDetails };
