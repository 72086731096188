import Button from '@swe/shared/ui-kit/components/button';
import Stack from '@swe/shared/ui-kit/components/stack';

import styles from './styles.module.scss';

import { OrderProcessStatus } from 'entities/common/orders';
import { isDone } from 'entities/profile/orders';
import { FullShopFulfillmentType, isPickup } from 'entities/shop/info';

type ActionsProps = {
  status: OrderProcessStatus;
  fulfillmentType: FullShopFulfillmentType;
  onChangePickupTime?: () => void;
  onShowQRCode?: () => void;
  onArrive?: () => void;
};

const Actions = ({ status, fulfillmentType, onArrive, onChangePickupTime, onShowQRCode }: ActionsProps) => {
  if (isDone(status)) return null;

  return (
    <Stack
      direction="row"
      wrap
    >
      {isPickup(fulfillmentType) && (
        <>
          {onArrive && (
            <Button
              className={styles.button}
              onClick={onArrive}
              name="arrived"
            >
              I have arrived
            </Button>
          )}
          {onChangePickupTime && (
            <Button
              className={styles.button}
              color="light"
              onClick={onChangePickupTime}
              name="change"
            >
              Change pickup window time
            </Button>
          )}
        </>
      )}
      {onShowQRCode && (
        <Button
          className={styles.button}
          color="light"
          onClick={onShowQRCode}
          name="show-qr-code"
        >
          Show QR code
        </Button>
      )}
    </Stack>
  );
};

export type { ActionsProps };
export { Actions };
