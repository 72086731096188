import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import styles from './styles.module.scss';

import { matchOrderStatusToType } from 'common/utils/order';
import { OrderProcessStatus } from 'entities/common/orders';

const getClassNameByStatus = (status: OrderProcessStatus) => {
  return matchOrderStatusToType(status, {
    preparing: styles._color_warning,
    delivering: styles._color_success,
    other: styles._color_neutral,
  });
};

type FulfillmentInformationProps = ComponentHasClassName & {
  status: OrderProcessStatus;
  addressPrefix?: string;
  address?: string;
  scheduleInfo?: string;
};

const FulfillmentInformation = ({
  className,
  status,
  addressPrefix,
  address,
  scheduleInfo,
}: FulfillmentInformationProps) => {
  return (
    <div className={cx(className, styles.root, getClassNameByStatus(status))}>
      <div>
        <span className={styles.type}>{addressPrefix}</span>
        {address && (
          <>
            {' '}
            <span className={styles.address}>{address}</span>
          </>
        )}
      </div>
      {scheduleInfo && <div className={styles.schedule}>{scheduleInfo}</div>}
    </div>
  );
};

export type { FulfillmentInformationProps };
export { FulfillmentInformation };
export default FulfillmentInformation;
