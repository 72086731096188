import Link from '@swe/shared/providers/router/link';
import Alert from '@swe/shared/ui-kit/components/alert';
import { DocumentsIcon } from '@swe/shared/ui-kit/components/icon';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { Routes } from 'common/router/constants';

type RequiredDocumentsAlertProps = ComponentHasClassName & {
  documentsRequiredNames: string;
};

const RequiredDocumentsAlert = ({ className, documentsRequiredNames }: RequiredDocumentsAlertProps) => {
  return (
    <Link
      href={Routes.ProfileDocuments}
      color="ghost"
    >
      <Alert
        className={className}
        icon={DocumentsIcon}
        title="Add documents"
        color="danger"
      >
        Documents required to receive order: {documentsRequiredNames}
      </Alert>
    </Link>
  );
};

export type { RequiredDocumentsAlertProps };
export { RequiredDocumentsAlert };
export default RequiredDocumentsAlert;
