import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { useRouterQuery } from 'common/router';
import { OrderDetails } from 'domains/profile/containers/order-details';

const TITLE = 'Order Details';

const ProfileOrderDetailsPage: ApplicationPage = () => {
  const query = useRouterQuery();

  return (
    <Page {...DEFAULT_AUTHORIZED_PAGE_PROPS}>
      <OrderDetails orderId={parseInt(String(query.id), 10)} />
    </Page>
  );
};

ProfileOrderDetailsPage.getMeta = () => ({ title: TITLE });

ProfileOrderDetailsPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileOrderDetailsPage;
