import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { Order } from 'entities/common/orders';

const NAME = '/Orders/EditInterval';
const EditIntervalEndpoint = createModernEndpoint<
  {
    id: number;
    fulfillmentDate: string;
    fulfillmentIntervalId: number | null;
  },
  Order
>(NAME);

export { EditIntervalEndpoint };
export default EditIntervalEndpoint;
